import * as React from "react";
import "./style.css";

class BtsSong extends React.Component {
  render() {
    return (
      <iframe
        className="bts-iframe"
        src={this.props.url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    );
  }
}

export default BtsSong;
