import React, { Component } from "react";

import BtsSong from "./songs/bts-song";

import "./style.css";

export default class BtsByte extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [
        "https://www.youtube.com/embed/c4V0FNZfEv0", // dynamite
        "https://www.youtube.com/embed/SmH9Bhp-1OQ", // mic drop performance
        "https://www.youtube.com/embed/0s9koEAXfLI", // peaches
        "https://www.youtube.com/embed/FHHKZp5gvl4", // promise
        "https://www.youtube.com/embed/2nY0spA5UL4", // snow flower
        "https://www.youtube.com/embed/djKdPZiJdvA", // still with you
        "https://www.youtube.com/embed/F8wI5ZosdRA", // seoul by rm
        "https://www.youtube.com/embed/GiN5hPrZgw8", // coffee
        "https://www.youtube.com/embed/jjskoRh8GTE", // i need u
        "https://www.youtube.com/embed/hmE9f-TEutc", // blood sweat tears
        "https://www.youtube.com/embed/XhQAAdoTdgw", // filter
        "https://www.youtube.com/embed/-5q5mZbe3V8", // life goes on
        "https://www.youtube.com/embed/Yb7tgdg4Wr4", // home
        "https://www.youtube.com/embed/RJSOdjarLtk", // airplanes pt 2
        "https://www.youtube.com/embed/D2xmlzMOsmM", // boy with luv
        "https://www.youtube.com/embed/uyWm8DUK06M", // singularity
        "https://www.youtube.com/embed/GZjt_sA2eso", // save me
        "https://www.youtube.com/embed/0lapF4DQPKQ", // black swan
        "https://www.youtube.com/embed/eXBu09fwe3I", // make it right
        "https://www.youtube.com/embed/AcAvNIZ5bDs", // stigma
        "https://www.youtube.com/embed/8D7Xxaq7sBU", // 2! 3!
        "https://www.youtube.com/embed/wKysONrSmew", // run
        "https://www.youtube.com/embed/MBdVXkSdhwU", // dna
      ],
      selectedNote: null,
      selectedPlaylist: <iframe id="bts-playlist"
      src="https://open.spotify.com/embed/playlist/2yCK5zIdOZWRWKkAGyW0sg?utm_source=generator"
      width="100%"
      height="380"
      frameBorder="0"
      allowfullscreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    />
    };
  }

  clickRandomSong = () => {
    this.setState({
      selectedNote: (
        <BtsSong
          url={
            this.state.notes[
              Math.floor(Math.random() * this.state.notes.length)
            ]
          }
        />
      ),
      selectedPlaylist: null,
    });
  };

  clickAllSongs = () => {
    this.setState({
      selectedNote: null,
      selectedPlaylist: <iframe id="bts-playlist"
      src="https://open.spotify.com/embed/playlist/2yCK5zIdOZWRWKkAGyW0sg?utm_source=generator"
      width="100%"
      height="380"
      frameBorder="0"
      allowfullscreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    />,
      
    });
  };

  render() {
    return (
      <div id="bts-byte">
        <div className="left-60" >
          <h1 className="big-text">bts :~) 💜💜</h1>
          <p>
            The Bangtan Boys (aka BTS) is a 7-member South Korean boy band under
            Big Hit Entertainment, debuting in 2013. Its members include RM,
            Jin, Suga, J-Hope, Jimin, V, and Jungkook.
          </p>
          <p>this page is still a work in progress!</p>
          <button onClick={this.clickAllSongs}>show me your playlist &rarr;</button>
          <button onClick={this.clickRandomSong}>rec me a song! &rarr;</button>
        </div>
        <div className="right-35">
          <div id="song-generator">
            <h1>{this.state.selectedNote}</h1>
            <h1>{this.state.selectedPlaylist}</h1>
          </div>
        </div>
      </div>
    );
  }
}
