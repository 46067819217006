import * as React from "react";

import Layout from "../../../components/layout";
import BtsByte from "../../../bytes/bts";
// import ExternalLink from "../../../components/external-link";
import "./style.css";

const BtsPage = () => {
  return (
    <Layout title="bts | 🧃">
      <div id="bts-page">
        <BtsByte />
      </div>
    </Layout>
  );
};

export default BtsPage;
